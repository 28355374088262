/* eslint-disable jsx-a11y/anchor-is-valid, consistent-return, no-unused-expressions */
import React, { FC, useContext, useEffect } from 'react';
import classnames from 'classnames';

import ButtonGroup from '@components/ButtonGroup';
import WPImage from '@features/WordPress/components/WPImage';
import CheckmarkIcon from '@icons/checkmark.svg';

import Container from '@components/Container';
import ACFButton from '@features/PageBuilder/components/Button';
import Input from '@components/Input';
import Button from '@components/Button';
import LocationsMap from '@features/PageBuilder/components/LocationsMap';
import i18nStringsContext from '@contexts/i18nStringsContext';
import Header from '@features/PageBuilder/components/Header';
import styles from './Hero.module.css';
const {
  Root,
  Inner,
  ImagePositionLeft,
  ImagePositionRight,
  ImagePositionFullWidth,
  Header: HeaderClass,
  HasButtons,
  CheckList,
  ButtonGroup: ClassButtonGroup,
  Button: ButtonClass,
  ContentContainer,
  ContentWrapper,
  ImageContainer,
  Image: ImageClass,
  MapContainer,
  HasBlackImageFade,
  Splash,
  TextPositionLeft,
  TextPositionRight,
  TextPositionCenter,
  Text: HeaderTextClass,
  Subtext: SubtextClass,
  AddressPickerWrapper,
  AddressPickerInput,
  AdressPickerSubmit,
  ReplacingWord,
  ReplacingWordIsVisible,
  ReplacingWordIsHidden,
  FirstVisibleReplacingWord,
  HasMap,
} = styles;
import HTMLText from '../../../HTMLText/HTMLText';
import { HeaderSettings, SectionButton } from '@shared/types';

const IMAGE_POSITIONS = {
  left: ImagePositionLeft,
  right: ImagePositionRight,
  full: ImagePositionFullWidth,
};
const TEXT_POSITIONS = {
  left: TextPositionLeft,
  right: TextPositionRight,
  center: TextPositionCenter,
};

interface HeroProps {
  className?: string;
  hasButtons: boolean;
  buttons: SectionButton[];
  imagePosition: 'left' | 'right' | 'full';
  textPosition?: 'left' | 'right' | 'center';
  header: string;
  subheader?: string;
  subtext?: React.ReactNode | string;
  text: React.ReactNode | string;
  image?: boolean | object;
  map: boolean | object;
  imageAddons?: object | React.ReactNode;
  showMap: boolean;
  headerSettings: HeaderSettings | {};
  /* TODO: type safe these */
  features?: any;
  checklist?: any;
  rotatingWords?: any;
}

const Hero: FC<HeroProps> = ({
  header,
  subheader = '',
  text = '',
  subtext = '',
  image = false,
  hasButtons = false,
  buttons = [],
  imagePosition = 'left',
  textPosition = 'left',
  className = '',
  features,
  checklist,
  map = false,
  rotatingWords,
  imageAddons = null,
  showMap = false,
  headerSettings = {},
}) => {
  const { addressAutocompletePlaceholder, becomeMember: becomeMemberString } =
    useContext(i18nStringsContext);
  const hasAddressPicker = features && features.includes('has_address_picker');
  const hasSplashColors = features && features.includes('has_splash_colors');
  const hasBlackImageFade = features && features.includes('black_image_fade');
  const hasChecklist =
    checklist && features && features.includes('has_checklist');

  const containerClasses = classnames(Root, {
    [HasBlackImageFade]: hasBlackImageFade,
    [HasMap]: map,
  });

  const classes = classnames(
    Inner,
    {
      [IMAGE_POSITIONS[imagePosition]]: IMAGE_POSITIONS[imagePosition],
      [TEXT_POSITIONS[textPosition]]: TEXT_POSITIONS[textPosition],
      [HasButtons]: hasButtons,
      [Splash]: hasSplashColors,
    },
    className
  );

  const bracketsRegex = /\{(.*?)\}/;
  const hasEffect = header.match(bracketsRegex) && rotatingWords.length > 0;

  const headerContent = !hasEffect
    ? header
    : header
        .split(bracketsRegex)
        .filter(Boolean)
        .map((t, i) => {
          if (header.match(bracketsRegex)![1] === t) {
            return `<div class="${ReplacingWord}"><span class="${ReplacingWordIsVisible} ${FirstVisibleReplacingWord}">${t}</span>${rotatingWords
              .map(
                ({ word }: { word: string }) =>
                  `<span class="${
                    i === 0 ? ReplacingWordIsVisible : null
                  }">${word}</span>`
              )
              .join('')}</div>`;
          }
          return t;
        })
        .join('');

  useEffect(() => {
    if (hasEffect) {
      const firstWord = document.querySelector(`.${ReplacingWord} span`);
      let currentWord = firstWord;
      const interval = setInterval(() => {
        currentWord?.classList.remove(ReplacingWordIsVisible);
        currentWord?.classList.remove(FirstVisibleReplacingWord);
        currentWord?.classList.add(ReplacingWordIsHidden);

        currentWord =
          (currentWord?.nextSibling as unknown as Element | null) || firstWord;
        currentWord?.classList.remove(ReplacingWordIsHidden);
        currentWord?.classList.add(ReplacingWordIsVisible);

        // to avoid word being on two lines, its current width will be
        // saved, and all words will have fixed width of the
        // longest word
        // if (currentWord.offsetWidth >= oldWord.offsetWidth) {
        const newWidth = currentWord.offsetWidth;
        document.querySelector(
          `.${ReplacingWord}`
        ).style.width = `${newWidth}px`;
        // }
      }, 3000);

      return () => {
        interval && clearInterval(interval);
      };
    }
  }, [headerContent, hasEffect]);
  return (
    <Container className={containerClasses}>
      <div className={classes}>
        {showMap && map && (
          <LocationsMap className={MapContainer} {...map}>
            {imageAddons}
          </LocationsMap>
        )}
        {!showMap && image ? (
          <div className={ImageContainer}>
            <WPImage
              className={ImageClass}
              layout="fill"
              {...image}
              isHeroImage
            />
            {imageAddons}
          </div>
        ) : null}
        <div className={ContentContainer}>
          <div className={ContentWrapper}>
            <Header
              element="div"
              subheader={subheader}
              text={headerContent}
              position={textPosition}
              splash={hasSplashColors}
              className={HeaderClass}
              headerSettings={headerSettings}
            />
            {text ? (
              <HTMLText className={HeaderTextClass}>{text}</HTMLText>
            ) : null}
          </div>
          {hasAddressPicker ? (
            <div className={AddressPickerWrapper}>
              <Input
                className={AddressPickerInput}
                type="text"
                placeholder={addressAutocompletePlaceholder}
              />
              <Button
                className={AdressPickerSubmit}
                appearance="primary"
                size="extra-large"
              >
                {becomeMemberString}
              </Button>
            </div>
          ) : null}

          {hasChecklist && (
            <ul className={CheckList}>
              {checklist.map(
                ({ text: checklistText }: { text: string }, index: number) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={text + index}
                  >
                    <CheckmarkIcon />
                    {checklistText}
                  </li>
                )
              )}
            </ul>
          )}

          {hasButtons ? (
            <ButtonGroup className={ClassButtonGroup}>
              {buttons
                // ensure button has a link
                .filter(({ link }) => !!link)
                .map(({ appearance, id, __typename: _, ...button }, index) => (
                  <ACFButton
                    className={ButtonClass}
                    size="extra-large"
                    appearance={
                      hasSplashColors && appearance === 'primary'
                        ? 'splash'
                        : appearance
                    }
                    /* attempt bugfix related to ITD-2368 */
                    {...(id && {
                      id,
                    })}
                    target={button.link.target}
                    key={JSON.stringify([index, id, appearance, button?.link])}
                    {...button}
                  />
                ))}
            </ButtonGroup>
          ) : null}
          {subtext ? (
            <HTMLText className={[ContentWrapper, SubtextClass].join(' ')}>
              {subtext}
            </HTMLText>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default Hero;
